.company_logo img{
    width:200px;
    height:100% !important;
    object-fit: cover;;
}

.appIcon{
    width:38px !important;
}

.laptop_screen_box{
    display: flex;
    justify-content: end;
}

.laptop_screen{
    width:80%;
    display: flex;
    justify-content: end;
    position: relative;
    /* bottom: 25px;; */
}

.logo img{
    width:80px !important;
}

.width50{
    width:50%;
}

.width70{
    width:70% !important;
}

.width80{
    width:80% !important;
}

.width90{
    width:90% !important;
}

.width60{
    width:60% !important;
}

.width40{
    width:40% !important;
}

.width10{
    width:10%;
}





/*  */

.height50{
    width:50%;
}

.height70{
    width:70% !important;
}

.height80{
    width:80% !important;
}

.height90{
    width:90% !important;
}

.height60{
    width:60% !important;
}

.height40{
    width:40% !important;
}

.height10{
    width:10%;
}

.product_services_img{
    height:160px !important;
    object-fit: cover;
    width:100%;
}

.industries_icon{
    width: 70%;
    position: absolute;
    transform: rotate(45deg);
    box-shadow: none !important;
}

.industries_icon2{
    width: 60%;
    position: absolute;
    transform: rotate(45deg);
    filter: invert(29%) sepia(45%) saturate(2832%) hue-rotate(227deg) brightness(90%) contrast(96%) !important;
    box-shadow: none !important;
}
.solution_menu {
    color: rgb(148 163 184 / 1) !important;
    /* padding-right:30px; */
    padding:0;
    box-shadow: none;
}

.solution_heading{
    position: relative;
    left:20px;
    padding-top:20px;
    border-bottom:1px solid rgb(148 163 184 / 1);
    width:84%;
    padding-bottom:10px;
}

.align-items-center{
    align-items: center;
}



.solution_sub_menu ul li{
    display: flex !important;
}

.appIcon_box{
    background:#e1e1e12e;
    width:70px;
    height:70px;
    border-radius:50%;
    padding:5px;
}

.solution_box{
    display: flex;
    justify-content: space-between;
    width:100%;
}

.radius-4{
    border-radius: 4px;;
}

.radius-12{
    border-radius: 12px;;
}

.solution_menu_box{
    width: 850px;
    visibility: hidden;
    opacity: 0;
    background:white;;
    padding:20px;
    position:absolute;
    right:30%;
    top:110%;
    display: flex;
    border-radius:4px;
    transition:0.3s;
    justify-content: space-between;
    left:-81%;
}

.solution_hover_menu:hover .solution_menu_box{
    visibility: visible;
    opacity: 1;;
    top:100%;

}

.solution_menu_box > div{
    width:calc(25% - 15px);
    padding:2px;
    /* border-bottom:1px solid black; */
}

.d-flex{
    display: flex;
}

.justify-content-end{
    justify-content: end;
}

.solution_menu_box ul{
    display: block !important;
}

.justify-content-center{
    justify-content: center;
}

.justify-content-between{
    justify-content: space-between;
}

.solution_menu_box ul li a{
    transition: all 0.3s;
    clear: both;
    display: block;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 17px;
    letter-spacing: 0.04em;
    --tw-text-opacity: 1;
    color: rgb(22 28 45 / var(--tw-text-opacity));
}

.solution_menu_box ul li a:hover{
    color:rgb(79 70 229);
}

.megamenu-head{
    color:rgb(184, 184, 184) !important;
    position: relative;
    left:22px;
}

.solution_sub_menu{
    position: absolute;
    background: white;
    /* right: 0; */
    display: none;
    /* visibility: hidden; */
    padding: 5px;
    border-radius: 4px;
    transition:0.5s;
    left: 20%;
}

.solution_sub_menu_hover:hover > div {
    display: block !important;
    /* visibility: visible; */
    transition:0.5s;

}

.pricing_plan_man{
    width:800px;
    /* background:red; */
    margin:auto;
    border-radius:8px;
    padding:40px;
}

.pricing_box_3{
    width:600px;
    /* background:red !important; */
    border-radius:8px;
    display:flex;
    align-items: center;
    margin:auto;
    padding:20px;
    justify-content: center;
    flex-flow: column;
}

.pricing_plan_man div:nth-child(1){
    width:40%;
    /* background:green; */
}

.pricing_plan_man div:nth-child(2){
    width:60%;
    /* background:red; */
}

.text-decoration-line-through{
    text-decoration: line-through;
}

.prices{
    position: relative;
    top:7px;
    right:13px;
}

.width20{
    width:20%;
}

.width30{
    width:25%;
}

.width18{
    width: 15% !important;;
}

.width15{
    width:12% !important;
}

.heading{
    font-size:43px !important;
}

.customer_img{
    width:100%;
    height: 240px;;
    object-fit: cover;
}

.cursor-pointer{
    cursor: pointer !important;
}
.price_man_box{
    display: flex;
    /* background:red; */
    justify-content: center !important;
}

.price_man_box > div{
    width:500px;
}

.client_logo{
    width:80% !important;
    object-fit: contain;
    /* height: 100px;; */
    mix-blend-mode: color-burn !important;
    aspect-ratio: 3/2;
    height: 60px;
    padding:0px 10px !important;
    /* filter: grayscale(100%); */
    border: 1px solid rgb(221, 221, 221);
    transition: 0.5s;;
}

.client_logo:hover{
    filter: grayscale(0%);
}

.medium_size{
    width:80% !important;
    position: relative;
    /* top:10px; */
    transition: 0.5s;;

}

.medium_size:hover{
    filter: grayscale(0%);

}


.pricing_feature_box:hover svg{
    fill:white !important;; 

}

.appIcon_box svg{
    color:red !important;
    fill: #4f46e5; 
}

.help_center_icon svg{
    fill: #4f46e5; 
    width:35px;
}

.swiper-pagination{
    display:none !important;
}

.visiblity-hidden{
    visibility: hidden;
}

.border-right{
    border-right:1.5px solid rgb(228, 228, 228);
    padding-right:20px;
}

.choose_us_img{
    height: 200px;;
    width:100%;
    object-fit: cover;;
}

.videokrypt_banner{
    background:#fbfbfd;
}

.check_icon{
    width:23px;
    height:23px;
    background:#4f46e5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    margin-right: 10px;;
    color:white;
    border-radius:50%;
}


.pos_img{
    height:100%;
    object-fit: cover;;
}

.hardware_box_font{
    font-size: 15px !important;;
}

.hardware_icon{
    width:160px;
    height: 70px;
    object-fit: contain;
}

.border-top {
    border-top:1px solid #cfcfcf;
}

.border-bottom{
    border-bottom:1px solid #cfcfcf;
}

.hover-gray:hover{
    background:#b4b4b4 !important;
}

.hardware_icon2{
    filter: invert(37%) sepia(77%) saturate(2912%) hue-rotate(359deg) brightness(100%) contrast(99%);

    width: 45px !important;
    
}










/* ============== Responsive Here Start=============== */

@media(max-width:1280px){
    .solution_menu_box > div {
        width: calc(50% - 15px);
        padding: 2px;
        /* border-bottom: 1px solid black; */
    }
}
@media(max-width:1035px){
   .md-mb-2{
    margin-bottom: 20px;;
   }
}

@media(max-width:548px){
    .heading{
        font-size:26px !important;
    }

    .text-slate-400{
        font-size: 17px !important;;
    }




}
/* ============== Responsive Here End =============== */




/* Get Started code */

.form-control {
    padding: 0.8rem 1.1rem;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.2em + 1.6rem + 2px);
    padding: 0.8rem 1.6rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.2;
    color: #131e3f;
    background-color: #ffffff;
    background-clip: padding-box;
    outline: none;;
    border: 1px solid #f2f3f7;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.get-started-textarea{
    height:80px;
    outline:none;
    border: 1px solid #f2f3f7;
    color: #131e3f;
    background-color: #ffffff;
    width:100%;
    border-radius: 0.25rem;

    padding: 0.8rem 1.6rem;

}

.bg-indigo-600{
    /* background:#fc5203 !important; */
}

.bg-indigo-600:hover{
    /* background:#fc5203 !important; */
}

.border-indigo-600{
    /* border:1px solid #fc5203 !important; */
}

.border-indigo-600:hover{
    /* border:1px solid #fc5203 !important; */
}

.hover:text-indigo-600{
    /* color:#fc5203 !important; */
}

.text-lg:hover{
    color:#0e015d !important;
    transition:0.5s;
}

.text-indigo-600{
    /* color:#fc5203 !important; */
}

.icon-orange{
filter: invert(37%) sepia(77%) saturate(2912%) hue-rotate(359deg) brightness(100%) contrast(99%);
}

.text-xl:hover{
    /* color:#fc5203 !important; */
}

.footer-list ul li{
    position:relative !important;
    right:100px !important;
}

.mr-1{
    position: relative;
    right:4px !important;;
}

.svg-top{
    position:relative;
    /* color:#fc5203 !important; */
    top:-8px;
    margin-right:10px;
}

body,.navbar-color-orange ul li a{
    /* color:red !important; */
}

#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li > a.active {
    /* color:#fc5203 !important; */
    
}

#topnav .navigation-menu > li:hover > .menu-arrow {
    /* color:#fc5203 !important; */
    /* border-color: #fc5203 !important; */

}

#topnav .navigation-menu > li .submenu li a:hover {
    /* color:#fc5203 !important; */
  
}

#topnav .navigation-menu > li.active > a {
    --tw-text-opacity: 1 !important;
    /* color:#fc5203 !important; */

}

#topnav .has-submenu.active.active .menu-arrow {
    /* border-color:#fc5203; */
}

#topnav .navigation-menu > li > a {
    /* color:#fc5203 !important; */

}

#topnav .navigation-menu .has-submenu .menu-arrow {
    /* border-color:#fc5203; */
 
}


.d-block{
    display: block !important;
}

.our-instructor{
    display: flex !important;
    justify-content: center;
}

.size-14 {
    height: 40px !important;
    width: 100px !important;
}

.header_profile{
    position: relative;
    top:20px;;
    left:120px;
    margin:10px 20px;
}

.header_login_btn {
    /* padding: 1px 13px; */
    background: #4f46e5;
    width: 81px;
    color: white;
    height: 32px;
    display: flex !important;
    justify-content: center;
    margin: auto;
    align-items: center;
    border-radius: 4px;
}